import customersAPI from 'api/customers'
import commonUtils from './common'
import setupTwilioChat from 'utils/setupTwilioChat'

import { FIREBASE_ENV, FIREBASE_WEB_PUSH_KEY, FIREBASE_CONFIG, LCL_CUSTOMER_URL } from 'constants/appConstants'
import firebase from 'firebase'
import i18n from 'i18n/i18n'

const firebaseInit = {
  load: async () => {
    if (firebase?.apps?.length === 0) {
      firebase.initializeApp(FIREBASE_CONFIG ? JSON.parse(FIREBASE_CONFIG) : {});
      const token = localStorage.getItem('access_token');

      if (token) {
        const twilioChatClient = await setupTwilioChat.getChatClient();
        firebaseInit.loadFCM(token, twilioChatClient);
      }
    }
  },

  authenticate: (token) => {
    if (token) {
      firebase.auth().signInWithCustomToken(token).catch((error) => {
        console.log('SignIn Error: ', error);
      });
    }
  },

  listen: (refPath) => {
    const path = `${FIREBASE_ENV}/${refPath}`;
    return firebase.database().ref(path);
  },

  listen_root: (refPath) => {
    return firebase.database().ref(refPath);
  },

  loadFCM: (authenticateToken, chatClientInstance) => {
    if ('serviceWorker' in navigator) {
      firebaseInit.registerServiceWorker(chatClientInstance);
    } else {
      // The current browser doesn't support service workers.
      console.log("The current browser doesn't support service workers.");
      firebaseInit.updateNotificationStatus(false);
    }
  },

  registerServiceWorker: (chatClientInstance) => {
    navigator.serviceWorker.addEventListener('message', () => {
      // check data and then play sound
      commonUtils.playSound('../../../assets/audio/FPD_notification_sound.mp3', 2)
    })
    const swConfig = {
      messageTitle:  i18n.t('notifications.chat.title'),
      lclCustomerUrl: LCL_CUSTOMER_URL
    }
    const queryString = new URLSearchParams(swConfig).toString()
    navigator.serviceWorker.register(`/my-sw.js?${queryString}`)
    navigator.serviceWorker.register('/my-sw.js')
      .then((registration) => {
        firebaseInit.initializeMessaging(registration, chatClientInstance);
      })
      .catch((err) => {
        // Something went wrong during registration. The service-worker.js file
        // might be unavailable or contain a syntax error.
        console.log('Unable to register service worker.', err);
        firebaseInit.updateNotificationStatus(false);
      });
  },

  initializeMessaging: (registration, chatClientInstance) => {
    const serviceWorker = registration.installing || registration.waiting || registration.active;

    if (serviceWorker) {
      firebase.messaging().useServiceWorker(registration);
      // [START request_permission]
      firebaseInit.requestNotificationPermission(chatClientInstance);
      commonUtils.createTmpIframe('/manifest.json');
    }
  },

  requestNotificationPermission: (chatClientInstance) => {
    firebase.messaging().requestPermission()
      .then(() => {
        firebaseInit.retrieveToken(chatClientInstance);
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
        firebaseInit.updateNotificationStatus(false);
      });
  },

  retrieveToken: (chatClientInstance) => {
    firebase.messaging().getToken({
      ...(FIREBASE_WEB_PUSH_KEY && { vapidKey: FIREBASE_WEB_PUSH_KEY })
    }).then((currentToken) => {
      if (currentToken) {
        firebaseInit.handleTokenReceived(currentToken, chatClientInstance);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token.', err);
      firebaseInit.updateNotificationStatus(false);
    });
  },

  handleTokenReceived: (currentToken, chatClientInstance) => {
    console.log('After request: ', currentToken);
    window.Moengage.add_user_attribute('Web Notifications', true);
    customersAPI.trackDevice(currentToken);

    if (chatClientInstance) {
      console.log('Setup Chat push notification');
      chatClientInstance.setPushRegistrationId('fcm', currentToken);
      firebase.messaging().onMessage((payload) => {
        firebaseInit.handlePushNotification(payload, chatClientInstance);
      });
    }
  },

  handlePushNotification: (payload, chatClientInstance) => {
    console.log('FirebaseSupport.registerForPushCallback', 'push received', payload);
    if (chatClientInstance) {
      chatClientInstance.handlePushNotification(payload);
    }
  },

  updateNotificationStatus: (status) => {
    window.Moengage.add_user_attribute('Web Notifications', status);
  }
};

export default firebaseInit
