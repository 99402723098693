import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/store'
import { clearVehicleTypeSettings } from 'store/actions/new_booking/extraServiceActionCreators'
import { setToFirstStep } from 'store/actions/new_booking/stepActionCreators'
import { handleSetIsLoginStep3 } from 'store/actions/common/customerActionCreators'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import CommonUtils from 'utils/common'
import ConfirmModal from 'components/common/Modal/ConfirmModal'

const GoToStep1 = ({ isNoStep3, confirmSwitchAccount }: { isNoStep3?: boolean, confirmSwitchAccount?: () => void }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (confirmSwitchAccount) confirmSwitchAccount()
    clearVehicleTypeSettings()(dispatch)
    setToFirstStep()(dispatch)
    dispatch(dataChangesActionsCreator.updateDataChange(true))
    handleSetIsLoginStep3(false)(dispatch)
    dispatch(accountManageActions.updateModalAccountManage(''))
  }
  const content = CommonUtils.isTransportify()
    ? t('errors.customer.different_offers_and_pricing').replace('Deliveree', 'Transportify')
    : t('errors.customer.different_offers_and_pricing')

  return (<ConfirmModal isNoStep3={isNoStep3} handleClick={handleClick} content={content} />)
}

export default GoToStep1
