import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import STypography from 'components/common/Styled/STypography'
import { ContentWrapper } from './Sindex'

// This modal contain only content main text and a button
const ConfirmModal = ({ isNoStep3, handleClick, content }: { isNoStep3?: boolean, handleClick: () => void, content: string },) => {
  const { t } = useTranslation()

  return (
    <ModalNormal
      topLevel={!isNoStep3}
      targetParent={true}
      closeVariant="g1"
      customFixedWidth={'460px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
    >
      <ContentWrapper>
        <STypography mb={20} align="center" fs={16}>
          {content}
        </STypography>
        <SButton
          w="100%"
          h="40px"
          fw={500}
          onClick={handleClick}
          br={`1px solid ${theme.colors.green[1]}`}
          variant="g2"
          type="button"
        >
          {t('webapp.new_booking.ok')}
        </SButton>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default ConfirmModal
