import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonUtils from 'utils/common'
import ConfirmModal from 'components/common/Modal/ConfirmModal'

const ReloadModal = ({ isNoStep3, confirmSwitchAccount }: { isNoStep3?: boolean, confirmSwitchAccount: () => void }) => {
  const { t } = useTranslation()

  const handleClick = () => confirmSwitchAccount()

  const content = CommonUtils.isTransportify()
    ? t('errors.customer.reload_when_switch_account').replace('Deliveree', 'Transportify')
    : t('errors.customer.reload_when_switch_account')

    return (<ConfirmModal isNoStep3={isNoStep3} handleClick={handleClick} content={content} />)
}

export default ReloadModal
